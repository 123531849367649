import React from "react"
import { Table } from "react-bootstrap"
import Loading from "./Loading"
import moment from 'moment'
import 'moment/locale/pt-br'
import { FaWhatsapp } from "react-icons/fa";

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { SparkLineChart } from '@mui/x-charts/SparkLineChart'


class Clients extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            clientsWTracking: [],
            pageReady: false,
        }

        this.clientsPhone = []
        this.tryPhone2 = []
        this.upClientsPhones = []

        this.dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')

        this.clientsLoaded = false
        this.whatsapp = []

    }

    async componentDidMount() {
        this.getClients()

        // while (!this.clientsLoaded) {
        //     await this.sleep(2000)
        // }
        // this.wappNumberMap()
    }

    componentWillUnmount() {
        this.clientsLoaded = false
    }


    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")
        var clientLength = 999999999

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {

                const clientsWTracking = []

                data.map(client => {
                    const tracking = client.tracking
                        .toSorted(function (a, b) {
                            var c = new Date(a.refDate);
                            var d = new Date(b.refDate);
                            return c - d;
                        })

                    const trackingInsideForMonth = []
                    const trackingInsideForVisits = []
                    const trackingInsideForTicket = []

                    for (let index = 1; index <= 12; index++) {

                        const monthVisit = moment.utc(new Date(this.dateToSetActive)).subtract(index, "month")
                        const hasVisitInMonth = tracking
                            .filter((track) => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthVisit.format('MMMM/YYYY') && track.ticket !== 0)

                        if (hasVisitInMonth.length !== 0) {
                            trackingInsideForMonth.push(monthVisit.format('MMMM/YYYY'))
                            trackingInsideForVisits.push(hasVisitInMonth[0].visits)
                            trackingInsideForTicket.push(hasVisitInMonth[0].ticket)
                        } else {
                            trackingInsideForMonth.push(monthVisit.format('MMMM/YYYY'))
                            trackingInsideForVisits.push(0)
                            trackingInsideForTicket.push(0)
                        }
                    }

                    clientsWTracking.push({
                        _id: client._id,
                        name: client.name,
                        phone1: client.phone1,
                        hasWhatsapp: client.hasWhatsapp,
                        first_date: client.first_date,
                        last_date: client.last_date,
                        birthday: client.birthday,
                        last_ticket: client.last_ticket,
                        last_last_ticket: client.last_last_ticket,
                        active: client.active,
                        tracking: {
                            months: trackingInsideForMonth.reverse(),
                            visits: trackingInsideForVisits.reverse(),
                            ticket: trackingInsideForTicket.reverse()
                        }
                    })
                    return 0
                })

                this.setState({
                    clients: data.toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1),
                    clientsWTracking: clientsWTracking.toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1)
                })
                clientLength = data.length
                this.clientsPhone = data
            })
            .catch(rejected => {
                console.log(rejected);
            })
            .finally(data => {
                if (clientLength !== 999999999) {
                    this.setState({ pageReady: true })
                    this.clientsLoaded = true
                }
            })

    }

    sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    updateClient = (data) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    console.log('UPDATING CLIENTS - COMPLETED!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    wappNumberMap = async () => {

        console.log("identifying whats numbers")


        console.log('PHONE 1')
        console.log(this.clientsPhone.length)
        for (let index = 0; index < this.clientsPhone.length; index++) {
            console.log(index + ' - PHONE 1')
            const element = this.clientsPhone[index]
            this.checkWappNumber(element, 'PHONE 1')
            await this.sleep(200)
        }

        console.log('PHONE 2')
        console.log(this.tryPhone2.length)
        for (let index = 0; index < this.tryPhone2.length; index++) {
            console.log(index + ' - PHONE 2')
            const element = this.tryPhone2[index]
            this.checkWappNumber(element, 'PHONE 2')
            await this.sleep(300)
        }

        console.log('END VECTOR')
        console.log(this.upClientsPhones)
        console.log(this.whatsapp)

        console.log('UPDATING CLIENTS')
        this.updateClient(this.upClientsPhones)
        this.updateClient(this.whatsapp)

    }

    checkWappNumber = (client, trying) => {

        const phone = trying !== 'PHONE 2'
            ? ((((this.phoneNumberCorrection(client.phone1)).replace('+', '')).replace(' ', '')).replace(' ', '')).replace(' ', '')
            : ((((this.phoneNumberCorrection(client.client.phone1)).replace('+', '')).replace(' ', '')).replace(' ', '')).replace(' ', '')

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/action/is-registered-user", {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            },
            body: JSON.stringify({ contactId: phone + '@c.us' })
        })
            .then(res => res.json())
            .then(data => {
                // if (data.data.data.isRegisteredUser) {
                //     this.whatsapp.push({
                //         _id: client._id,
                //         hasWhatsapp: true
                //     })
                // } else {
                //     console.log(client.name)
                // }
                if (!data.data.data.isRegisteredUser && trying !== 'PHONE 2') { //nao tem wapp phone1 inicial
                    if (client.phone2 !== '') {
                        this.tryPhone2.push({ client: client })
                        console.log('Phone2 to try: ' + this.tryPhone2.length)
                    }
                } else { //tem wapp
                    if (trying === 'PHONE 2') {  //tem wapp phone2 - trocar p phone1
                        this.upClientsPhones.push({
                            _id: client.client._id,
                            phone1: client.client.phone2,
                            phone2: client.client.phone1
                        })
                        console.log('Phone2 found: ' + this.upClientsPhones.length)
                    } else { //tem wapp phone 1 inicial
                        this.whatsapp.push({
                            _id: client._id,
                            hasWhatsapp: true
                        })
                    }
                }

            })
            .catch(err => console.error('error:' + err))
    }

    uppercaseEachWords = (sentence) => {

        if (typeof sentence !== 'undefined') {
            const words = sentence.split(' ')

            return words.map((word) => {
                if (word.length > 1) {
                    return word[0] + word.substring(1).toLowerCase()
                } else {
                    return word[0]
                }
            }).join(" ")
        }

        return sentence
    }

    phoneNumberCorrection = (phone) => {

        if (typeof phone !== 'undefined' && phone !== '') {

            const removeSpaces = phone.replace(' ', '')
            const removeTrace = removeSpaces.replace('-', '')

            //check DDI
            if (removeTrace[0] === '+') {
                return removeTrace
            }

            //remove first zero DDD
            if (removeTrace[0] === '0') {
                if (removeTrace.slice(1).length >= 10) { //correct number already
                    return '+55 '
                        + (removeTrace.slice(1)).slice(0, 2)
                        + ' '
                        + ((removeTrace.slice(1)).slice(2)).slice(0, ((removeTrace.slice(1)).slice(2)).length - 4)
                        + ' '
                        + ((removeTrace.slice(1)).slice(2)).slice(((removeTrace.slice(1)).slice(2)).length - 4, ((removeTrace.slice(1)).slice(2)).length)
                } else { //incorrect number
                    return ''
                }
            } else {
                if (removeTrace.length >= 10) { //correct number already
                    return '+55 '
                        + removeTrace.slice(0, 2)
                        + ' '
                        + (removeTrace.slice(2)).slice(0, (removeTrace.slice(2)).length - 4)
                        + ' '
                        + (removeTrace.slice(2)).slice((removeTrace.slice(2)).length - 4, (removeTrace.slice(2)).length)
                } else { // without DDD
                    // console.log('INCORRETO: ' + phone)
                    return '+55 11 '
                        + removeTrace.slice(0, removeTrace.length - 4)
                        + ' '
                        + removeTrace.slice(removeTrace.length - 4, removeTrace.length)
                }
            }
        }
        return ''
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.pageReady
                        ?
                        <>
                            <h3>Lista de clientes</h3> ({this.state.clients.length} clientes na base)

                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Telefone</th>
                                        <th>WhatsApp</th>
                                        <th>Primeira visita</th>
                                        <th>Última visita</th>
                                        <th>Aniversário</th>
                                        <th>Gasto mês passado</th>
                                        {/* <th>Ticket mês retrasado</th> */}
                                        {/* <th>Situação (90dias)</th> */}
                                        <th>Visitas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.clientsWTracking
                                            .map((client) =>
                                                <tr key={client._id}>
                                                    <td>{this.uppercaseEachWords(client.name)}</td>
                                                    <td>{this.phoneNumberCorrection(client.phone1)}</td>
                                                    {client.hasWhatsapp
                                                        ? <td style={{ color: '#24d366' }}><FaWhatsapp /></td>
                                                        : <td style={{ color: '#ed4545' }}>Nao tem</td>
                                                    }
                                                    <td>{moment.utc(new Date(client.first_date)).format('DD/MM/YYYY')}</td>
                                                    <td>{moment.utc(new Date(client.last_date)).format('DD/MM/YYYY')}</td>
                                                    <td>{client.birthday ? moment.utc(new Date(client.birthday)).format('MMMM/YYYY') : "-"}</td>
                                                    <td>R$ {(typeof client.last_ticket !== 'undefined') ? (client.last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td>
                                                    {/* <td>R$ {(typeof client.last_last_ticket !== 'undefined') ? (client.last_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td> */}
                                                    {/* <td style={client.active ? { color: '#24d366' } : { color: '#ed4545' }}>{client.active ? 'Ativo' : 'Inativo'}</td> */}
                                                    <td>
                                                        <Stack direction="row" >
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <SparkLineChart
                                                                    plotType="bar"
                                                                    data={client.tracking.visits}
                                                                    height={30}
                                                                    width={100}
                                                                    colors={['#24d366']}
                                                                    showTooltip
                                                                    showHighlight
                                                                    xAxis={{
                                                                        scaleType: 'band',
                                                                        data: client.tracking.months
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </td>
                                                </tr>

                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Clients;