import React from "react"
import { Text } from 'react-native'
import { Table, Card, CardGroup, Badge, Button } from "react-bootstrap"
import FileInputBirthday from "./FileInputBirthday"
import FileInputBestClients from "./FileInputBestClients"
import Loading from "./Loading"
import moment from 'moment'
import 'moment/locale/pt-br'


class ImportData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            importDateDiaries: [],
            importDateDiariesDescending: [],
            today: [],
            yesterday: [],
            birthdayToImport: moment(new Date()),
            campaignsId: ""
        }

    }

    componentDidMount() {
        this.getImportDateDiaries()
        this.getCampaignsId()
    }

    componentWillUnmount() {

    }

    getCampaignsId = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/id/' + localStorage.getItem("resd"), {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                localStorage.setItem("camp", data)
                this.setState({ campaignsId: data })

            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getMonthExpectedToImport = (type, data) => {

        const importData = data
            .sort(function (a, b) {
                var c = new Date(a.date);
                var d = new Date(b.date);
                return c - d;
            })

        const monthExpectedToImportLong = []
        var monthExpected = 'Not Found'

        importData.map((importDiary) => {
            if (importDiary.bestClients.total === 0 && monthExpected === 'Not Found') {
                monthExpected = 'Found'
                monthExpectedToImportLong.push(
                    {
                        month: moment.utc(new Date(importDiary.date)).format('MMMM/YYYY'),
                        date: moment.utc(new Date(importDiary.date)).format('YYYY-MM-15T00:00:00')
                    }
                )
            }
            return 0
        })
        if (monthExpected !== 'Not Found') {
            if (type === 'date') {
                return monthExpectedToImportLong[0].date
            } else {
                return monthExpectedToImportLong[0].month
            }
        } else {
            monthExpectedToImportLong.push(
                {
                    month: moment.utc(new Date()).format('MMMM/YYYY'),
                    date: moment.utc(new Date()).format('YYYY-MM-15T00:00:00')
                })

            if (type === 'date') {
                return monthExpectedToImportLong[0].date
            } else {
                return monthExpectedToImportLong[0].month
            }
        }
    }

    getImportDateDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    importDateDiaries: data,
                    importDateDiariesDescending: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return c - d;
                        }),
                    today: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return d - c;
                        })[0],
                    yesterday: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return d - c;
                        })
                        .filter(diary => moment.utc(new Date(diary.date)).format('MMMM/YYYY') === moment(new Date()).subtract(1, 'months').format('MMMM/YYYY'))[0]
                        .bestClients
                })

            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.importDateDiaries.length > 0
                        ?
                        <>
                            <h3>Importe os arquivos indicados</h3>

                            <CardGroup>
                                <Card border="" >
                                    <Card.Body>
                                        <Card.Title>Aniversariantes do  mês</Card.Title>
                                        <Card.Text>Carregue o mês indicado
                                            <br></br>
                                            <br></br>{moment.utc(new Date(this.state.birthdayToImport)).format('MMMM/YYYY')} -
                                            {' '}{moment.utc(new Date(this.state.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(this.state.today.date)).format('MMMM/YYYY')
                                                && this.state.today.birthday.total !== 0
                                                ? <Badge bg="" style={{ backgroundColor: '#24d366' }}>Importado</Badge>
                                                : <Badge bg="warning" text="dark">Importação pendente</Badge>}

                                        </Card.Text>
                                    </Card.Body>
                                    {
                                        this.state.campaignsId !== ""
                                            ? <Card.Footer>
                                                {
                                                    moment.utc(new Date(this.state.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(this.state.today.date)).format('MMMM/YYYY')
                                                        && typeof this.state.today !== 'undefined' && this.state.today.birthday.total !== 0
                                                        ? <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Aguarde o próximo mês</Text></>
                                                        : <FileInputBirthday />
                                                }
                                            </Card.Footer>
                                            : <Card.Footer>
                                                {
                                                    <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Carregando...</Text></>
                                                }
                                            </Card.Footer>
                                    }
                                </Card>
                                <Card border="" >
                                    <Card.Body>
                                        <Card.Title>Melhores clientes</Card.Title>
                                        <Card.Text>Carregue o período indicado
                                            <br></br>
                                            <br></br>De 01/{moment(new Date(this.getMonthExpectedToImport('date', this.state.importDateDiariesDescending))).format('MM/YYYY')}{' '}
                                            a {moment(new Date(this.getMonthExpectedToImport('date', this.state.importDateDiariesDescending))).daysInMonth()}
                                            {moment(new Date(this.getMonthExpectedToImport('date', this.state.importDateDiariesDescending))).format('/MM/YYYY')} -
                                            {' '}{typeof this.state.yesterday !== 'undefined' && this.state.yesterday.total !== 0
                                                ? <Badge bg="" style={{ backgroundColor: '#24d366' }}>Aguardar</Badge>
                                                : <Badge bg="warning" text="dark">Importação pendente</Badge>}
                                        </Card.Text>
                                    </Card.Body>
                                    {
                                        this.state.campaignsId !== ""
                                            ? <Card.Footer>
                                                {
                                                    typeof this.state.yesterday !== 'undefined' && this.state.yesterday.total !== 0
                                                        ? <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Aguarde o próximo mês</Text></>
                                                        : <FileInputBestClients />
                                                }
                                            </Card.Footer>
                                            : <Card.Footer>
                                                {
                                                    <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Carregando...</Text></>
                                                }
                                            </Card.Footer>
                                    }
                                </Card>
                            </CardGroup>

                            <div>
                                <br></br>
                            </div>

                            <h3>Histórico de importações</h3>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Data referente</th>
                                        <th>Aniversariantes</th>
                                        <th>Melhores clientes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.importDateDiaries
                                            .sort(function (a, b) {
                                                var c = new Date(a.date);
                                                var d = new Date(b.date);
                                                return d - c;
                                            })
                                            .map((importDateDiary) =>
                                                <tr key={importDateDiary._id}>
                                                    <td>{moment.utc(new Date(importDateDiary.date)).format('MMMM/YYYY')}</td>
                                                    <td>{importDateDiary.birthday.actives} considerados de {importDateDiary.birthday.total} na base
                                                        <br></br>
                                                        {importDateDiary.birthday.total !== 0
                                                            ? <Text style={{ color: 'gray' }}>Importado em {moment(new Date(importDateDiary.birthday.importDate)).format('DD/MM/YYYY [às] HH:mm')}</Text>
                                                            : <Text style={{ color: 'gray' }}>Não importado</Text>}

                                                    </td>
                                                    <td>{importDateDiary.bestClients.total} na base
                                                        <br></br>
                                                        {importDateDiary.bestClients.total !== 0
                                                            ? <Text style={{ color: 'gray' }}>Importado em {moment(new Date(importDateDiary.bestClients.importDate)).format('DD/MM/YYYY [às] HH:mm')}</Text>
                                                            : <Text style={{ color: 'gray' }}>Não importado</Text>}
                                                    </td>
                                                </tr>

                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default ImportData;