import { React, useRef } from 'react'
import * as XLSX from 'xlsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Button } from "react-bootstrap"

function FileInputBirthday() {

    const hiddenFileInput = useRef(null)

    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = event => {
        handleFileUpload(event)
    }

    const userId = localStorage.getItem("resd")
    const campId = localStorage.getItem("camp")

    const daysToCutData = 2 * 365
    const daysToBeActive = 90
    const dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')

    const monthToImportBirthday = moment.utc(new Date()).format('MMMM')
    const dateToImportBirthday = moment.utc(new Date()).format('YYYY-MM-15T00:00:00')

    const clients = []
    const clientsRequest = []
    const birthdayRequest = []

    const importDateDiaries = []
    const diaries = []

    const clientsAlive = []
    const campaigns = []

    let clientsGotten = false

    getClients()

    getImportDateDiaries()

    getDiaries()

    getCampaigns()

    const handleFileUpload = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = async (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)

            if (sheetData[0].__EMPTY_1 === 'Lista de Aniversariantes') {

                const fileMonth = (sheetData[0].__EMPTY_2).split(' ')

                if (fileMonth[fileMonth.length - 1].toLowerCase() === monthToImportBirthday) {

                    while (!clientsGotten) {
                        // console.log("wait clients request")
                        await sleep(1000)
                    }

                    await setClientsBirthday(sheetData)

                    var totalDBclients = 0

                    clients.map((client) => {
                        if (client.last_date !== '' && client.last_date !== 'Invalid Date') {
                            totalDBclients += 1
                            if ((moment.utc(new Date(client.last_date)).diff(moment.utc(new Date(dateToSetActive)), 'days')) >= -daysToCutData) {
                                clientsRequest.push(client)
                            }
                        }
                        return 0
                    })

                    const clientsToUpdate = getClientsToUpdate(clientsRequest, clientsAlive)
                    if (clientsToUpdate.length > 0) {
                        await updateClient(clientsToUpdate)
                    }

                    const clientsToCreate = getClientsToCreate(clientsRequest, clientsAlive)
                    if (clientsToCreate.length > 0) {
                        await createClient(clientsToCreate)
                    }

                    await createImportDateDiary(clientsRequest, totalDBclients)

                    await createDiary()

                    await updateCampaigns({ status_camp1: 0 }, campId)

                    alert("Arquivo importado com sucesso!")
                    window.location.reload()

                } else {
                    alert('\nImportação do mês incorreto!\n\nVocê está importando o mês de ' + fileMonth[fileMonth.length - 1].toLowerCase())
                }
            } else {
                alert("\nO arquivo escolhido não é a lista de aniversariantes compatível")
            }

        }

        reader.readAsBinaryString(file);

    }

    async function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    async function setClientsBirthday(data) {

        data.map((client) =>

            clients.push({
                userId: userId,
                birthday: (typeof client.__EMPTY != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY - 1)).toUTCString()
                    : typeof client.__EMPTY != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY)).toUTCString()
                        : ''),
                name: (typeof client.__EMPTY_1 != 'undefined' ? client.__EMPTY_1 : ''),
                phone1: (typeof client.__EMPTY_2 != 'undefined' ? client.__EMPTY_2 : ''),
                phone2: (typeof client.__EMPTY_3 != 'undefined' ? client.__EMPTY_3 : ''),
                first_date: (typeof client.__EMPTY_5 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)).toUTCString()
                    : typeof client.__EMPTY_5 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_5)).toUTCString()
                        : ''),
                tracking: {
                    last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                        : typeof client.__EMPTY_6 != 'undefined'
                            ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                            : ''),
                    ticket: 0,
                    visits: 0,
                    items: 0,
                    refDate: dateToImportBirthday
                },
                last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                last_last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                ticket: 0,
                last_ticket: 0,
                last_last_ticket: 0,
                last_active_ticket: 0,
                camp_history: {
                    refDate: dateToImportBirthday,
                    camp1: false,
                    camp1_date:"",
                    camp2: false,
                    camp2_date:"",
                    camp3: false,
                    camp3_date:"",
                    camp4: false,
                    camp4_date:"",
                    camp5: false,
                    camp5_date:""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? (moment.utc(new Date(dateToSetActive)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString())), 'days') < daysToBeActive ? true : false)
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? (moment.utc(new Date(dateToSetActive)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString())), 'days') < daysToBeActive ? true : false)
                        : false)
            })

        )

    }

    function getClientsToUpdate(data, allClients) {

        const clientsToUpdate = []

        data.filter(function (elem) {

            const cli = allClients.find(({ name, first_date }) => (elem.name).toLowerCase() === name.toLowerCase()
            && moment.utc(new Date(elem.first_date)).format('YYYY-MM-DD') === moment.utc(new Date(first_date)).format('YYYY-MM-DD'))

            if (typeof cli !== 'undefined') {
                clientsToUpdate.push({
                    phone1: elem.phone1,
                    phone2: elem.phone2,
                    birthday: elem.birthday,
                    last_date: elem.last_date,
                    last_last_date: cli.last_date === elem.last_date ? cli.last_last_date : cli.last_date,
                    active: elem.active,
                    _id: cli._id
                })
                return cli
            } else {
                return undefined
            }
        })

        if (clientsToUpdate.length >= 1) {
            return clientsToUpdate
        } else {
            return []
        }
    }

    function getClientsToCreate(data, allClients) {

        const clientsToCreate = data.filter((elem) => !allClients.find(({ name, first_date }) => (elem.name).toLowerCase() === name.toLowerCase()
        && moment.utc(new Date(elem.first_date)).format('YYYY-MM-DD') === moment.utc(new Date(first_date)).format('YYYY-MM-DD')))

        if (clientsToCreate.length >= 1) {
            return clientsToCreate
        } else {
            return []
        }
    }

    async function updateClient(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function createClient(clientsVector) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(clientsVector)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function createDiary() {

        const diariesSorted = diaries.sort(function (a, b) {
            var c = new Date(a.ref_date);
            var d = new Date(b.ref_date);
            return d - c;
        })

        const monthToImport = getMonthToImportDiary(diariesSorted)

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const diaryVectorPATCH = {
                userId: userId,

                ref_date: monthToImport.ref_date,

                date_camp1: "",
                total_camp1_sent: 0,
                total_camp1_goal: 0,
                total_camp1_ticket: 0,

                date_camp2: "",
                total_camp2_sent: 0,
                total_camp2_goal: 0,
                total_camp2_ticket: 0,

                date_camp3: "",
                total_camp3_sent: 0,
                total_camp3_goal: 0,
                total_camp3_ticket: 0,

                date_camp4: "",
                total_camp4_sent: 0,
                total_camp4_goal: 0,
                total_camp4_ticket: 0
            }

            patchDiary(diaryVectorPATCH, monthToImport._id)

        } else {

            const diaryVectorPOST = {
                userId: userId,

                ref_date: new Date(dateToImportBirthday).toUTCString(),

                date_camp1: "",
                total_camp1_sent: 0,
                total_camp1_goal: 0,
                total_camp1_ticket: 0,

                date_camp2: "",
                total_camp2_sent: 0,
                total_camp2_goal: 0,
                total_camp2_ticket: 0,

                date_camp3: "",
                total_camp3_sent: 0,
                total_camp3_goal: 0,
                total_camp3_ticket: 0,

                date_camp4: "",
                total_camp4_sent: 0,
                total_camp4_goal: 0,
                total_camp4_ticket: 0
            }

            postDiary(diaryVectorPOST)

        }
    }

    async function createImportDateDiary(data, totalDBclients) {

        const dataSortedByDate = data.sort(function (a, b) {
            var c = new Date(a.last_date);
            var d = new Date(b.last_date);
            return d - c;
        })

        dataSortedByDate.map((client) => {
            if ((moment.utc(new Date(client.last_date)).diff(moment.utc(new Date(dateToSetActive)), 'days')) >= -daysToCutData) {
                birthdayRequest.push(client)
            }
            return 0
        })

        const importDateDiariesSorted = importDateDiaries.sort(function (a, b) {
            var c = new Date(a.date);
            var d = new Date(b.date);
            return d - c;
        })

        const monthToImport = getMonthToImport(importDateDiariesSorted)

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const birthdayVectorPATCH = {

                userId: userId,

                date: monthToImport.date,

                birthday: {
                    total: totalDBclients,
                    actives: birthdayRequest.length,
                    importDate: new Date().toUTCString()
                },

                newClient: {
                    total: monthToImport.newClient.total,
                    actives: monthToImport.newClient.actives,
                    importDate: monthToImport.newClient.importDate
                },

                bestClients: {
                    total: monthToImport.bestClients.total,
                    actives: monthToImport.bestClients.actives,
                    importDate: monthToImport.bestClients.importDate
                }
            }

            patchBiDiary(birthdayVectorPATCH, monthToImport._id)

        } else {

            const birthdayVectorPOST = {

                userId: userId,

                date: new Date(dateToImportBirthday).toUTCString(),

                birthday: {
                    total: totalDBclients,
                    actives: birthdayRequest.length,
                    importDate: new Date().toUTCString()
                },

                newClient: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                },

                bestClients: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                }
            }

            postBiDiary(birthdayVectorPOST)

        }

    }

    function patchBiDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function patchDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postBiDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getImportDateDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    importDateDiaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    diaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getClients() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    clientsAlive.push(diary)
                    return 0
                })
                clientsGotten = true
                // console.log("acabou o request")
                // console.log(data.length)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getCampaigns() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                campaigns.push(data)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function updateCampaigns(campaigns, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    // console.log(res)
                } else {
                    console.log(res)
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function getMonthToImport(data) {

        const birthdayMonthAndYear = moment.utc(new Date(birthdayRequest[0].birthday)).format('MMMM') + moment.utc(new Date()).format('/YYYY')
        const birthdayMonthAndYear_alive = data.filter(bidiary => moment.utc(new Date(bidiary.date)).format('MMMM/YYYY') === birthdayMonthAndYear)

        if (birthdayMonthAndYear_alive.length >= 1) {
            return birthdayMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function getMonthToImportDiary(data) {

        const diaryMonthAndYear = moment.utc(new Date(birthdayRequest[0].birthday)).format('MMMM') + moment.utc(new Date()).format('/YYYY')
        const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === diaryMonthAndYear)

        if (diaryMonthAndYear_alive.length >= 1) {
            return diaryMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    return (
        <>

            <Button variant="dark" size="sm" onClick={handleClick} >
                Importar
            </Button>

            <input type="file" id="formId" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />

        </>
    )
}

export default FileInputBirthday;