import React from "react"
import { Card, CardGroup, Badge, Image } from 'react-bootstrap'
import Loading from "./Loading"
import 'moment/locale/pt-br'
// import ModalPwd from "./ModalPwd"


class Configs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            diaries: [],
            today: [],
            img_qrcode: '',

            userId: localStorage.getItem("resd"),
            username: '',
            name: '',
            cnpj: '',
            phone: '',
            email: '',
            place: '',

            wapp_status: '',
            wapp_info_number: '',
            wapp_info_name: '',

            pageReady: false,
            hasQrCode: false,

        }

        this.onPage = true
    }

    componentDidMount() {
        this.getUser(this.state.userId)
        this.checkStatus()
    }

    componentWillUnmount() {
        this.onPage = false
    }

    getUser = (id) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/user/' + id, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    username: data.username,
                    name: data.name,
                    cnpj: data.cnpj,
                    phone: data.phone,
                    email: data.email,
                    place: data.places[0].name
                })
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    formatCPForCNPJ = (data) => {

        const leanData = data.toString()

        if (leanData.length === 11) { //cpf
            return leanData.slice(0, 3) + '.'
                + leanData.slice(3, 6) + '.'
                + leanData.slice(6, 9) + '-'
                + leanData.slice(9)
        }
        if (leanData.length === 14) { //cnpj
            return leanData.slice(0, 2) + '.'
                + leanData.slice(2, 5) + '.'
                + leanData.slice(5, 8) + '/'
                + leanData.slice(8, 12) + '-'
                + leanData.slice(12)
        }
        return '' // invalid format
    }

    formatUserPhoneNumber = (data) => {

        const leanData = data.toString()

        if (leanData.length === 13) {
            return leanData.slice(0, 3) + ' '
                + leanData.slice(3, 5) + ' '
                + leanData.slice(5, 9) + '-'
                + leanData.slice(9, 13)
        }
        if (leanData.length === 14) {
            return leanData.slice(0, 3) + ' '
                + leanData.slice(3, 5) + ' '
                + leanData.slice(5, 10) + '-'
                + leanData.slice(10, 14)
        }

    }

    checkStatus = () => {

        if (this.onPage) {
            this.getWappStatus()

            setTimeout(() => {
                this.checkStatus()
            }, 3000)
        } else {
            return
        }
    }

    getWappStatus = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch('https://waapi.app/api/v1/instances/' + instance_id + '/client/status', {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === "success") {
                    this.setState({
                        wapp_status: data.clientStatus.instanceStatus
                    })
                    if (data.clientStatus.instanceStatus === "ready") {
                        this.getClientInfo()
                        this.setState({ hasQrCode: false })
                    } else if (data.clientStatus.instanceStatus === "booting") {
                        this.setState({ pageReady: true })
                    } else {
                        this.getQrCode()
                        this.setState({ hasQrCode: true })
                    }
                }
            })
            .catch(err => console.error('error:' + err))

    }

    getQrCode = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/qr", {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    img_qrcode: data.qrCode.status !== 'error'
                        ? data.qrCode.data.qr_code
                        : ''
                })
                this.setState({ pageReady: true })
            })
            .catch(err => console.error('error:' + err))
    }

    getClientInfo = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/me", {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    wapp_info_name: data.me.data.displayName,
                    wapp_info_number: data.me.data.formattedNumber,
                    wapp_info_photo: data.me.data.profilePicUrl
                })
                this.setState({ pageReady: true })
            })
            .catch(err => console.error('error:' + err))
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.pageReady
                        ?
                        <>
                            <h3>Configuração do seu WhatsApp</h3>

                            <CardGroup>
                                <Card border="" className="text-center">
                                    <Card.Body>
                                        {this.state.img_qrcode !== '' && this.state.wapp_status !== 'ready'
                                            ? <>
                                                <Card.Title>Escaneie o QR Code</Card.Title>
                                                <Card.Img variant="top" src={this.state.img_qrcode}
                                                    style={{
                                                        width: '62%',
                                                        alignSelf: 'center'
                                                    }} />
                                                <Card.Text>
                                                    <Badge bg="warning" text="dark">Aguardando</Badge>
                                                </Card.Text>
                                            </>
                                            : <>
                                                <Card.Title>QR Code em uso</Card.Title>
                                                <Card.Img variant="top" src='./images/qrcode_desabled.png'
                                                    style={{
                                                        width: '62%',
                                                        alignSelf: 'center'
                                                    }} />
                                                <Card.Text>
                                                    <Badge bg="" style={{ backgroundColor: '#24d366' }}>Conectado</Badge>
                                                </Card.Text>
                                            </>
                                        }
                                    </Card.Body>
                                </Card>

                                <Card border="" className="text-center">
                                    <Card.Body>
                                        <Card.Title>Informações do WhatsApp</Card.Title>
                                        {this.state.img_qrcode === '' && this.state.wapp_status === 'ready'
                                            ? <Card.Text>
                                                <br></br>
                                                Conectado como
                                                <br></br>
                                                <br></br>
                                                <Image src={this.state.wapp_info_photo} roundedCircle
                                                    style={{
                                                        width: '20%',
                                                        alignSelf: 'center'
                                                    }} />
                                                <br></br>
                                                <br></br>
                                                <b>{this.state.wapp_info_name}</b>
                                                <br></br>
                                                <b>{this.state.wapp_info_number}</b>
                                            </Card.Text>
                                            : this.state.img_qrcode === '' && this.state.wapp_status === 'booting'
                                                ?
                                                <Card.Text>
                                                    <br></br>
                                                    Você precisa excluir nosso serviço dos
                                                    <br></br>
                                                    dispositivos logados do seu WhatsApp
                                                    <br></br>
                                                    ou
                                                    <br></br>
                                                    entre em contato conosco para auxiliarmos
                                                    <br></br>
                                                    BIWAPP WhatsApp +55 11 93950-1767
                                                </Card.Text>
                                                : <Card.Text>
                                                    <br></br>
                                                    Aguardando escanear o QR Code
                                                    <br></br>
                                                    para sincronizar seu número de WhatsApp
                                                    <br></br>
                                                </Card.Text>}
                                    </Card.Body>
                                </Card>

                                <Card border="" className="text-center">

                                    <Card.Body>
                                        <Card.Title>Informações da conta{' '}<img
                                            alt=""
                                            src="./images/biwapp-logo-black.png"
                                            width="90px"
                                            height="auto"
                                        />
                                        </Card.Title>
                                        <br></br>
                                        <Card.Text><b>{this.state.place}</b></Card.Text>
                                        <Card.Text>{this.formatCPForCNPJ(this.state.cnpj)}</Card.Text>
                                        <br></br>
                                        <Card.Text>{this.state.name}</Card.Text>
                                        <Card.Text>{this.formatUserPhoneNumber(this.state.phone)}</Card.Text>
                                        <Card.Text>{this.state.email}</Card.Text>
                                        <br></br>
                                        {/* <ModalPwd></ModalPwd> */}
                                    </Card.Body>

                                </Card>

                            </CardGroup>

                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Configs;