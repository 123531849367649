import { React } from 'react'
import { Text } from 'react-native'
import { Spinner } from 'react-bootstrap'
import 'moment/locale/pt-br'

function Loading() {

    return (
        <>
            <div className="Loading">
                <Spinner animation="border" variant="secondary" className="text-center" />
                <Text style={{ color: 'gray' }}>  Carregando...</Text>
            </div>
        </>
    )
}

export default Loading;