import { React, useRef } from 'react'
import * as XLSX from 'xlsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Button } from "react-bootstrap"

function FileInputBestClients() {

    const hiddenFileInput = useRef(null)

    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = event => {
        handleFileUpload(event)
    }

    const userId = localStorage.getItem("resd")
    const campId = localStorage.getItem("camp")

    const daysToBeActive = 90

    const clients = []
    const clientsRequest = []
    const bestClientsRequest = []

    const importDateDiaries = []
    const monthExpectedToImportLong = []

    const biDiaries = []
    const diaries = []
    const clientsAlive = []

    let clientsGotten = false


    getClients()

    getImportDateDiaries()

    getBiDiaries()

    getDiaries()

    const handleFileUpload = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = async (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)

            if (sheetData[0].__EMPTY_1 === 'Melhores Clientes') {

                const fileMonth = (sheetData[0].__EMPTY_2).split(' ')
                const fileEndDate = fileMonth[fileMonth.length - 1]
                const fileMonthAndYear = fileEndDate.slice(3)
                const monthExpectedToImport = getMonthExpectedToImport()

                if (fileMonthAndYear === monthExpectedToImport) {

                    while (!clientsGotten) {
                        // console.log("wait clients request")
                        await sleep(1000)
                    }

                    await setClientsBest(sheetData)

                    clients.map((client) => {
                        if (client.last_date !== '' && client.last_date !== 'Invalid Date') {
                            clientsRequest.push(client)
                        }
                        return 0
                    })

                    const clientsRequestSortedByDate = sortByDate(clientsRequest)
                    await createImportDateDiary(clientsRequestSortedByDate)

                    const clientsToUpdate = getClientsToUpdate(bestClientsRequest, clientsAlive)
                    if (clientsToUpdate.length > 0) {
                        await updateClient(clientsToUpdate)
                    }
                    const clientsToUpdateZeroTicket = getClientsToUpdateZeroTicket(clientsAlive, clientsToUpdate)
                    if (clientsToUpdateZeroTicket.length > 0) {
                        await updateClient(clientsToUpdateZeroTicket)
                    }
                    const clientsToCreate = getClientsToCreate(bestClientsRequest, clientsAlive)
                    if (clientsToCreate.length > 0) {
                        await createClient(clientsToCreate)
                    }

                    const totalClientsOnTheBIWApp = [...clientsToUpdate, ...clientsToUpdateZeroTicket, ...clientsToCreate]
                    await createBiDiary(clientsRequestSortedByDate, clientsToUpdate, totalClientsOnTheBIWApp)

                    await updateDiaryConvertion(clientsToUpdate)

                    await updateCampaigns({ status_camp2: 0, status_camp3: 0 }, campId)

                    alert("Arquivo importado com sucesso!")
                    window.location.reload()

                } else {
                    alert('\nImportação do período incorreto!'
                        + '\n\nVocê está importando o mês de ' + fileMonthAndYear
                        + '\n\nPrecisamos do mês de ' + monthExpectedToImport)
                }
            } else {
                alert("\nO arquivo escolhido não é a lista de aniversariantes compatível")
            }

        }

        reader.readAsBinaryString(file);

    }

    async function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    function getMonthExpectedToImport() {

        var monthExpected = 'Not Found'

        importDateDiaries.map((importDiary) => {
            if (importDiary.bestClients.total === 0 && monthExpected === 'Not Found') {
                monthExpected = moment.utc(new Date(importDiary.date)).format('MM/YYYY')
                monthExpectedToImportLong.push(
                    {
                        month: moment.utc(new Date(importDiary.date)).format('MMMM/YYYY'),
                        date: moment.utc(new Date(importDiary.date)).format('YYYY-MM-15T00:00:00')
                    }
                )
            }
            return 0
        })

        return monthExpected

    }

    async function updateDiaryConvertion(data) {

        const diariesSorted = diaries.sort(function (a, b) {
            var c = new Date(a.date_camp1);
            var d = new Date(b.date_camp1);
            return d - c;
        })

        const monthToImport = getMonthToImportDiary(diariesSorted)
        let campDiariesRefData = ""
        if (monthToImport.length !== 0 && monthToImport._id !== 0){
            campDiariesRefData = monthToImport.ref_date
        } else { 
            campDiariesRefData = monthExpectedToImportLong[0].date
        }

        const monthAndYearOfCampaign = monthExpectedToImportLong[0].month //moment.utc(new Date(importDateDiaries[0].date)).format('MMMM/YYYY')

        const camp1Targets = data
            .filter(client =>
                client.camp1 === monthAndYearOfCampaign
                && ((client.tracking
                    .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthAndYearOfCampaign 
                    && track.ticket > 0
                    && (moment.utc(new Date(track.last_date)).diff(moment.utc(new Date(campDiariesRefData)), 'days') >= 0))).length > 0)
                && (getTrackingInfo(client))
            )
        // console.log(data
        //     .filter(client =>
        //         client.camp1 === monthAndYearOfCampaign))
        // console.log(data
        //     .filter(client =>
        //         client.camp1 === monthAndYearOfCampaign
        //         && ((client.tracking
        //             .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthAndYearOfCampaign && track.ticket > 0)).length > 0)))
        // console.log(data
        //     .filter(client =>
        //         client.camp1 === monthAndYearOfCampaign
        //         && ((client.tracking
        //             .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthAndYearOfCampaign && track.ticket > 0)).length > 0)
        //         && (getTrackingInfo(client))))
        const camp2Targets = data
            .filter(client =>
                client.camp2 === monthAndYearOfCampaign
                && client.last_ticket !== 0
            )
        const camp3Targets = data
            .filter(client =>
                client.camp3 === monthAndYearOfCampaign
                && client.last_ticket !== 0
            )
        const camp4Targets = data
            .filter(client =>
                client.camp4 === monthAndYearOfCampaign
                && client.last_ticket !== 0
            )

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const diaryVectorPATCH = {

                userId: userId,

                total_camp1_goal: camp1Targets.length,
                total_camp1_ticket: camp1Targets.reduce((n, { last_ticket }) => n + last_ticket, 0),

                total_camp2_goal: camp2Targets.length,
                total_camp2_ticket: camp2Targets.reduce((n, { last_ticket }) => n + last_ticket, 0),

                total_camp3_goal: camp3Targets.length,
                total_camp3_ticket: camp3Targets.reduce((n, { last_ticket }) => n + last_ticket, 0),

                total_camp4_goal: camp4Targets.length,
                total_camp4_ticket: camp4Targets.reduce((n, { last_ticket }) => n + last_ticket, 0)
            }

            patchDiary(diaryVectorPATCH, monthToImport._id)

        } else {
        }


        const biDiaryTotalBiwapp = {
            userId: userId,

            date: monthToImport.length > 0 ? monthToImport.date : moment.utc(new Date(monthExpectedToImportLong[0].date)),

            total_biwapp: camp1Targets.length + camp3Targets.length

        }

        const monthToImportBi = getMonthToImport(sortByDateDiaries(biDiaries))

        if (monthToImportBi.length !== 0 && monthToImportBi._id !== 0) {

            patchBiDiary(biDiaryTotalBiwapp, monthToImportBi._id)

        } else {

            postBiDiary(biDiaryTotalBiwapp)

        }

    }

    function getTrackingInfo(client) {

        const index = client.tracking
            .findIndex(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month && track.ticket > 0)

        if (index > 0) {
            if (moment.utc(new Date(client.tracking[index].last_date)).format('MMMM/YYYY') === moment.utc(new Date(client.tracking[index].refDate)).format('MMMM/YYYY')) {
                if ((moment.utc(new Date(client.tracking[index].last_date)).diff(moment.utc(new Date(client.tracking[index - 1].refDate)), 'days')) > daysToBeActive) {
                    return true
                }
            } else {
                if ((moment.utc(new Date(client.tracking[index].refDate)).diff(moment.utc(new Date(client.tracking[index - 1].refDate)), 'days')) > daysToBeActive) {
                    return true
                }
            }
            return false
        } else {
            return true
        }
    }

    function getMonthToImportDiary(data) {

        const diaryMonthAndYear = monthExpectedToImportLong[0].month
        const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.date_camp1)).format('MMMM/YYYY') === diaryMonthAndYear)

        if (diaryMonthAndYear_alive.length >= 1) {
            return diaryMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function patchDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function setClientsBest(data) {

        data.map((client) =>

            clients.push({
                userId: userId,
                name: (typeof client.__EMPTY_1 != 'undefined' ? client.__EMPTY_1 : ''),
                phone1: (typeof client.__EMPTY_2 != 'undefined' ? client.__EMPTY_2 : ''),
                phone2: (typeof client.__EMPTY_3 != 'undefined' ? client.__EMPTY_3 : ''),
                first_date: (typeof client.__EMPTY_5 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)).toUTCString()
                    : typeof client.__EMPTY_5 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_5)).toUTCString()
                        : ''),
                tracking: {
                    last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                        : typeof client.__EMPTY_6 != 'undefined'
                            ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                            : ''),
                    ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                    visits: (typeof client.__EMPTY_8 != 'undefined' ? client.__EMPTY_8 : ''),
                    items: (typeof client.__EMPTY_9 != 'undefined' ? client.__EMPTY_9 : ''),
                    refDate: monthExpectedToImportLong[0].date
                },
                last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                last_last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                last_ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                last_last_ticket: 0,
                last_active_ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                camp_history: {
                    refDate: monthExpectedToImportLong[0].date,
                    camp1: false,
                    camp1_date:"",
                    camp2: false,
                    camp2_date:"",
                    camp3: false,
                    camp3_date:"",
                    camp4: false,
                    camp4_date:"",
                    camp5: false,
                    camp5_date:""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? (moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString())), 'days') < daysToBeActive ? true : false)
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? (moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString())), 'days') < daysToBeActive ? true : false)
                        : false)
            })
        )

    }

    async function createClient(clientsVector) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(clientsVector)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function updateClient(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function createBiDiary(dataImportedSorted, updated, totalClientsOnTheBIWApp) {

        const monthToImport = getMonthToImport(sortByDateDiaries(biDiaries))

        const biDiaryObjPOSTorPATCH = {

            userId: userId,

            date: monthToImport.length > 0 ? monthToImport.date : moment.utc(new Date(monthExpectedToImportLong[0].date)),

            total_clients: totalClientsOnTheBIWApp.length,
            total_actives: totalClientsOnTheBIWApp.filter(client => client.active).length,
            total_repeat: updated.filter(client => client.last_ticket > 0 && client.last_last_ticket > 0).length,
            total_dnr: totalClientsOnTheBIWApp.filter(client => !client.active).length,
            total_newclient: dataImportedSorted.filter(client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month).length,
        }

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            patchBiDiary(biDiaryObjPOSTorPATCH, monthToImport._id)

        } else {

            postBiDiary(biDiaryObjPOSTorPATCH)

        }

    }

    function sortByDate(data) {
        const dataSortedByDate = data.sort(function (a, b) {
            var c = new Date(a.last_date);
            var d = new Date(b.last_date);
            return d - c;
        })
        return dataSortedByDate
    }

    function sortByDateDiaries(data) {
        const dataSortedByDate = data.sort(function (a, b) {
            var c = new Date(a.date);
            var d = new Date(b.date);
            return d - c;
        })
        return dataSortedByDate
    }

    async function createImportDateDiary(data) {

        data.map((client) => {
            if ((moment.utc(new Date()).diff(moment.utc(new Date(client.last_date)), 'days')) < 365) {
                bestClientsRequest.push(client)
            }
            return 0
        })

        const newClient = data.filter(client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month)

        const monthToImport = getMonthToImport(sortByDateDiaries(importDateDiaries))

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const bestClientsVectorPATCH = {
                date: monthToImport.date,

                birthday: {
                    total: monthToImport.birthday.total,
                    actives: monthToImport.birthday.actives,
                    importDate: monthToImport.birthday.importDate
                },

                newClient: {
                    total: newClient.length,
                    actives: newClient.length,
                    importDate: new Date().toUTCString()
                },

                bestClients: {
                    total: data.length,
                    actives: bestClientsRequest.filter(client => client.active).length,
                    importDate: new Date().toUTCString()
                }
            }

            patchImportDateDiary(bestClientsVectorPATCH, monthToImport._id)

        } else {

            const bestClientsPOST = {
                date: moment.utc(new Date(bestClientsRequest[0].last_date)),

                birthday: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                },

                newClient: {
                    total: newClient.length,
                    actives: newClient.length,
                    importDate: new Date().toUTCString()
                },

                bestClients: {
                    total: data.length,
                    actives: bestClientsRequest.length,
                    importDate: new Date().toUTCString()
                }
            }

            postImportDateDiary(bestClientsPOST)

        }


        const biDiaryTotalVisitors = {
            userId: userId,

            date: monthToImport.length > 0 ? monthToImport.date : moment.utc(new Date(monthExpectedToImportLong[0].date)),

            total_visitors: data.length,

        }

        const monthToImportBi = getMonthToImport(sortByDateDiaries(biDiaries))

        if (monthToImportBi.length !== 0 && monthToImportBi._id !== 0) {

            patchBiDiary(biDiaryTotalVisitors, monthToImportBi._id)

        } else {

            postBiDiary(biDiaryTotalVisitors)

        }

    }

    function patchImportDateDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postImportDateDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function patchBiDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/biDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postBiDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/biDiary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getImportDateDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return c - d;
                    })
                    .map((diary) => {
                        importDateDiaries.push(diary)
                        return 0
                    })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getBiDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/biDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    biDiaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    diaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function updateCampaigns(campaigns, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    // console.log(res)
                } else {
                    console.log(res)
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getClients() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    clientsAlive.push(diary)
                    return 0
                })
                clientsGotten = true
                // console.log("acabou o request")
                // console.log(data.length)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function getMonthToImport(diaries) {

        const dataToImportMonthAndYear = monthExpectedToImportLong[0].month
        const dataToImportMonthAndYear_alive = diaries.filter(bidiary => moment.utc(new Date(bidiary.date)).format('MMMM/YYYY') === dataToImportMonthAndYear)

        if (dataToImportMonthAndYear_alive.length >= 1) {
            return dataToImportMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function getClientsToUpdate(data, allClients) {

        const clientsToUpdate = []

        data.filter(function (elem) {

            const cli = allClients.find(({ name, first_date }) => (elem.name).toLowerCase() === name.toLowerCase()
                && moment.utc(new Date(elem.first_date)).format('YYYY-MM-DD') === moment.utc(new Date(first_date)).format('YYYY-MM-DD'))

            if (typeof cli !== 'undefined') {

                var tracking1 = cli.tracking
                var tracking2 = elem.tracking

                const hasVisitInMonth = tracking1
                    .findIndex((track) => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === moment.utc(new Date(monthExpectedToImportLong[0].date)).format('MMMM/YYYY'))

                if (hasVisitInMonth < 0) {
                    tracking1.push(tracking2)
                } else {
                    tracking1[hasVisitInMonth].items = tracking2.items
                    tracking1[hasVisitInMonth].last_date = tracking2.last_date
                    tracking1[hasVisitInMonth].refDate = tracking2.refDate
                    tracking1[hasVisitInMonth].ticket = tracking2.ticket
                    tracking1[hasVisitInMonth].visits = tracking2.visits
                }

                clientsToUpdate.push({
                    name: cli.name,
                    tracking: tracking1,
                    last_ticket: elem.ticket,
                    last_last_ticket: cli.last_ticket,
                    ticket: cli.ticket + elem.ticket,
                    last_active_ticket: elem.ticket,
                    camp1: cli.camp1,
                    camp2: cli.camp2,
                    camp3: cli.camp3,
                    camp4: cli.camp4,
                    camp5: cli.camp5,
                    phone1: elem.phone1,
                    first_date: cli.first_date,
                    last_date: elem.last_date,
                    last_last_date: cli.last_date === elem.last_date ? cli.last_last_date : cli.last_date,
                    active: (moment.utc(new Date(elem.last_date)).diff(moment.utc(new Date(monthExpectedToImportLong[0].date)), 'days') <= -daysToBeActive ? false : true),
                    _id: cli._id
                })
                return cli
            } else {
                return undefined
            }
        })

        if (clientsToUpdate.length >= 1) {
            return clientsToUpdate
        } else {
            return []
        }
    }

    function getClientsToUpdateZeroTicket(allClients, data) {

        const anotherClients = []

        allClients.filter(function (elem) {

            const cli = data.find(({ name, first_date }) => (elem.name).toLowerCase() === name.toLowerCase()
                && moment.utc(new Date(elem.first_date)).format('YYYY-MM-DD') === moment.utc(new Date(first_date)).format('YYYY-MM-DD'))

            if (typeof cli !== 'undefined') {
                return cli
            } else {
                anotherClients.push({
                    name: elem.name,
                    last_ticket: 0,
                    last_last_ticket: elem.last_ticket,
                    phone1: elem.phone1,
                    last_date: elem.last_date,
                    active: (moment.utc(new Date(elem.last_date)).diff(moment.utc(new Date(monthExpectedToImportLong[0].date)), 'days') <= -daysToBeActive ? false : true),
                    _id: elem._id
                })
                return undefined
            }
        })

        if (anotherClients.length >= 1) {
            return anotherClients
        } else {
            return []
        }
    }

    function getClientsToCreate(data, allClients) {

        const clientsToCreate = data.filter((elem) => !allClients.find(({ name, first_date }) => (elem.name).toLowerCase() === name.toLowerCase()
            && moment.utc(new Date(elem.first_date)).format('YYYY-MM-DD') === moment.utc(new Date(first_date)).format('YYYY-MM-DD')))

        if (clientsToCreate.length >= 1) {
            return clientsToCreate
        } else {
            return []
        }
    }

    return (
        <>

            <Button variant="dark" size="sm" onClick={handleClick} >
                Importar
            </Button>

            <input type="file" id="formId" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />

        </>
    )
}

export default FileInputBestClients;