import React from "react"
import { Table, Card, Col, Row } from "react-bootstrap"
import { Text } from 'react-native'
import moment from 'moment'
import 'moment/locale/pt-br'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Loading from "./Loading"
import { BsInfoCircle } from "react-icons/bs"


class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            diaries: [],
            today: [],
            avgConversion: {
                camp1: 0,
                camp2: 0,
                camp3: 0,
                camp4: 0,
                camp5: 0
            }
        }

    }

    componentDidMount() {
        this.getDiaries();
    }

    componentWillUnmount() {

    }

    getDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {

                this.setState({
                    diaries: data,
                    today: data
                        .map(diary => { return diary })
                        .filter(diary => moment.utc(new Date(diary.date_camp1)).format('MMMM/YYYY') === moment.utc(new Date()).subtract(1, 'month').format('MMMM/YYYY'))[0]
                })

                const avgConversion_camp1 = data.filter(diary =>
                    moment.utc(new Date(diary.date_camp1)).format('MMMM/YYYY') !== moment.utc(new Date(this.todayDate)).format('MMMM/YYYY')
                    && diary.total_camp1_sent !== 0)
                const avgConversion_camp2 = data.filter(diary =>
                    moment.utc(new Date(diary.date_camp2)).format('MMMM/YYYY') !== moment.utc(new Date(this.todayDate)).format('MMMM/YYYY')
                    && diary.total_camp2_sent !== 0)
                const avgConversion_camp3 = data.filter(diary =>
                    moment.utc(new Date(diary.date_camp3)).format('MMMM/YYYY') !== moment.utc(new Date(this.todayDate)).format('MMMM/YYYY')
                    && diary.total_camp3_sent !== 0)
                const avgConversion_camp4 = data.filter(diary =>
                    moment.utc(new Date(diary.date_camp4)).format('MMMM/YYYY') !== moment.utc(new Date(this.todayDate)).format('MMMM/YYYY')
                    && diary.total_camp4_sent !== 0)

                this.setState({
                    avgConversion: {
                        camp1: avgConversion_camp1.length > 0 ? avgConversion_camp1.reduce((n, { total_camp1_ticket }) => n + total_camp1_ticket, 0) / avgConversion_camp1.length : 0,
                        camp2: avgConversion_camp2.length > 0 ? avgConversion_camp2.reduce((n, { total_camp2_ticket }) => n + total_camp2_ticket, 0) / avgConversion_camp2.length : 0,
                        camp3: avgConversion_camp3.length > 0 ? avgConversion_camp3.reduce((n, { total_camp3_ticket }) => n + total_camp3_ticket, 0) / avgConversion_camp3.length : 0,
                        camp4: avgConversion_camp4.length > 0 ? avgConversion_camp4.reduce((n, { total_camp4_ticket }) => n + total_camp4_ticket, 0) / avgConversion_camp4.length : 0
                    }
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    testRowClick = (row) => {
        // console.log("clicou: " + row)
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.diaries.length > 0
                        ?
                        <>
                            <h3>Conversão das campanhas</h3>

                            <Row xs={1} md={4} className="g-4">
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title>
                                                Aniversário
                                            </Card.Title>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.today.total_camp1_sent > 0 ? (Math.abs(((this.state.today.total_camp1_goal) / (this.state.today.total_camp1_sent) * 100).toFixed(0))) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.today.total_camp1_sent > 0 ? (Math.abs(((this.state.today.total_camp1_goal) / (this.state.today.total_camp1_sent) * 100).toFixed(0)) + '%') : 0 + '%'}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text>
                                                <br></br>
                                                {this.state.today.total_camp1_sent > 0 ? <><a style={{ color: 'gray' }} href={'/clientsbirthday/' + moment.utc(new Date(this.state.today.date_camp1)).format('YYYY-MM-15')}><BsInfoCircle /></a>{' '}</> : ""}<b>{this.state.today.total_camp1_goal}</b> de {this.state.today.total_camp1_sent} envios
                                                <br></br>
                                                <br></br>
                                                <b>R$ {(this.state.today.total_camp1_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno estimado | ref. {moment.utc(new Date(this.state.today.date_camp1)).format('MMM/YYYY')}</span>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}><b>Retorno médio total | R$ {(this.state.avgConversion.camp1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b></span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title>Sentimos sua falta</Card.Title>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.today.total_camp3_sent > 0 ? (Math.abs(((this.state.today.total_camp3_goal) / (this.state.today.total_camp3_sent) * 100).toFixed(0))) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.today.total_camp3_sent > 0 ? (Math.abs(((this.state.today.total_camp3_goal) / (this.state.today.total_camp3_sent) * 100).toFixed(0)) + '%') : 0 + '%'}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text>
                                                <br></br>
                                                {this.state.today.total_camp3_sent > 0 ? <><a style={{ color: 'gray' }} href={'/clientsmissyou/' + moment.utc(new Date(this.state.today.date_camp3)).format('YYYY-MM-15')}><BsInfoCircle /></a>{' '}</> : ""}<b>{this.state.today.total_camp3_goal}</b> de {this.state.today.total_camp3_sent} envios
                                                <br></br>
                                                <br></br>
                                                <b>R$ {(this.state.today.total_camp3_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}</b>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno estimado | ref. {moment.utc(new Date(this.state.today.date_camp3)).format('MMM/YYYY')}</span>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}><b>Retorno médio total | R$ {(this.state.avgConversion.camp3).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b></span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title>Saindo do padrão (em breve)</Card.Title>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.today.total_camp4_sent > 0 ? (Math.abs(((this.state.today.total_camp4_goal) / (this.state.today.total_camp4_sent) * 100).toFixed(0))) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.today.total_camp4_sent > 0 ? (Math.abs(((this.state.today.total_camp4_goal) / (this.state.today.total_camp4_sent) * 100).toFixed(0))) + '%' : 0 + '%'}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text>
                                                <br></br>
                                                <b>{this.state.today.total_camp4_goal}</b> de {this.state.today.total_camp4_sent} envios
                                                <br></br>
                                                <br></br>
                                                <b>R$ {(this.state.today.total_camp4_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno estimado | ref. {moment.utc(new Date(this.state.today.date_camp4)).format('MMM/YYYY')}</span>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}><b>Retorno médio total | R$ {(this.state.avgConversion.camp4).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b></span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title>Cliente novo</Card.Title>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.today.total_camp2_sent > 0 ? (Math.abs(((this.state.today.total_camp2_goal) / (this.state.today.total_camp2_sent) * 100).toFixed(0))) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.today.total_camp2_sent > 0 ? (Math.abs(((this.state.today.total_camp2_goal) / (this.state.today.total_camp2_sent) * 100).toFixed(0)) + '%') : 0 + '%'}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text>
                                                <br></br>
                                                {this.state.today.total_camp2_sent > 0 ? <><a style={{ color: 'gray' }} href={'/clientsnew/' + moment.utc(new Date(this.state.today.date_camp2)).format('YYYY-MM-15')}><BsInfoCircle /></a>{' '}</> : ""}<b>{this.state.today.total_camp2_goal}</b> de {this.state.today.total_camp2_sent} envios
                                                <br></br>
                                                <br></br>
                                                <b>R$ {(this.state.today.total_camp2_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno estimado | ref. {moment.utc(new Date(this.state.today.date_camp2)).format('MMM/YYYY')}</span>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}><b>Retorno médio total | R$ {(this.state.avgConversion.camp2).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b></span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                            <div>
                                <br></br>
                            </div>

                            <h3>Histórico das campanhas</h3>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Aniversário</th>
                                        <th></th>
                                        <th></th>
                                        <th>Sentimos sua falta</th>
                                        <th></th>
                                        <th></th>
                                        <th>Saindo do padrão</th>
                                        <th></th>
                                        <th></th>
                                        <th>Cliente novo</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.diaries
                                            .sort(function (a, b) {
                                                var c = new Date(a.ref_date);
                                                var d = new Date(b.ref_date);
                                                return d - c;
                                            })
                                            .slice(0, 6)
                                            .map((diary) =>
                                                <tr key={diary._id} onClick={() => this.testRowClick(diary.ref_date)}>
                                                    <td>{moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY')}</td>
                                                    <td>{diary.total_camp1_sent > 0 ? <><a style={{ color: 'gray' }} href={'/clientsbirthday/' + moment.utc(new Date(diary.date_camp1)).format('YYYY-MM-DDTHH:mm:ss')}><BsInfoCircle /></a>{' '}</> : ""}{diary.total_camp1_goal} de {diary.total_camp1_sent} envios</td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>{diary.total_camp1_goal !== 0 ? ((diary.total_camp1_goal / diary.total_camp1_sent) * 100).toFixed(0) : 0}%</Text>
                                                    </td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>R$ {(diary.total_camp1_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </td>
                                                    <td>{diary.total_camp3_sent > 0 ? <><a style={{ color: 'gray' }} href={'/clientsmissyou/' + moment.utc(new Date(diary.date_camp3)).format('YYYY-MM-DDTHH:mm:ss')}><BsInfoCircle /></a>{' '}</> : ""}{diary.total_camp3_goal} de {diary.total_camp3_sent} envios</td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>{diary.total_camp3_goal !== 0 ? ((diary.total_camp3_goal / diary.total_camp3_sent) * 100).toFixed(0) : 0}%</Text>
                                                    </td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>R$ {(diary.total_camp3_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </td>
                                                    <td>{diary.total_camp4_goal} de {diary.total_camp4_sent} envios</td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>{diary.total_camp4_goal !== 0 ? ((diary.total_camp4_goal / diary.total_camp4_sent) * 100).toFixed(0) : 0}%</Text>
                                                    </td>
                                                    <td>
                                                        {' '}<Text style={{ color: 'gray' }}>R$ {(diary.total_camp4_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </td>
                                                    <td>{diary.total_camp2_goal} de {diary.total_camp2_sent} envios</td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>{diary.total_camp2_goal !== 0 ? ((diary.total_camp2_goal / diary.total_camp2_sent) * 100).toFixed(0) : 0}%</Text>
                                                    </td>
                                                    <td>
                                                        <Text style={{ color: 'gray' }}>R$ {(diary.total_camp2_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </td>

                                                </tr>

                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Home;